import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
	width: 90%;
	max-width: 120rem;
	margin: 0 auto;
`
const TempContainer = ({ children, ...props }) => {
	return <StyledContainer {...props}>{children}</StyledContainer>
}

export { TempContainer }
