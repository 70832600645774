import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { Box, Heading, Text } from "../gazebo"
import { TempContainer } from "./TempContainer"
import { TempButton } from "./TempButton"

const StyledGetInTouch = styled.footer`
	width: 100%;
	padding: 6.4rem 0;
`

const StyledItems = styled.div`
	display: flex;
	justify-content: center;
	padding: 3.2rem 0;
	margin-bottom: 4rem;

	& a:first-of-type {
		margin-right: 4.8rem;
	}

	@media screen and (max-width: 700px) {
		flex-direction: column;
		width: 80%;
		margin: 0 auto;
		& a {
			margin-bottom: 1.6rem;
		}
	}
`
const GetInTouch = () => {
	return (
		<StyledGetInTouch>
			<TempContainer>
				<Heading style={{ textAlign: "center" }} mb={4}>
					Get in Touch
				</Heading>
				<StyledItems>
					<a href="https://www.linkedin.com/in/rebecca-fipphen-lsw-11381567/">
						<div style={{ display: "flex", alignItems: "center" }}>
							<StaticImage
								src="../images/icons/linkedin.svg"
								width={50}
								layout="constrained"
								quality={100}
								formats={["AUTO", "WEBP", "AVIF"]}
								alt="notes"
								placeholder="blurred"
							/>
							<Box ml={2}>
								<Text style={{ fontWeight: 700 }}>LINKEDIN</Text>
								<Text>rebecca-fipphen-lsw-11381567</Text>
							</Box>
						</div>
					</a>
					<a href="mailto:bfipps108@gmail.com">
						<div style={{ display: "flex", alignItems: "center" }}>
							<StaticImage
								src="../images/icons/open-email.svg"
								width={50}
								layout="constrained"
								quality={100}
								formats={["AUTO", "WEBP", "AVIF"]}
								alt="notes"
								placeholder="blurred"
							/>
							<Box ml={2}>
								<Text style={{ fontWeight: 700 }}>EMAIL</Text>
								<Text>bfipps108@gmail.com</Text>
							</Box>
						</div>
					</a>
				</StyledItems>

				<div style={{ display: "flex", justifyContent: "center", paddingBottom: "2.4rem" }}>
					<a href="https://becca-fipphen-content.s3.amazonaws.com/rebecca-fipphen-resume.pdf">
						<TempButton>DOWNLOAD RESUME</TempButton>
					</a>
				</div>
			</TempContainer>
		</StyledGetInTouch>
	)
}

export { GetInTouch }
