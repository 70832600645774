import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import { Burger } from "./Burger"
import { Menu } from "./Menu"
import styled from "styled-components"
import logo from "../images/main-logo.png"
import { TempButton } from "./TempButton"

export const useOnClickOutside = (ref, handler) => {
	useEffect(() => {
		const listener = (event) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return
			}
			handler(event)
		}
		document.addEventListener("mousedown", listener)
		return () => {
			document.removeEventListener("mousedown", listener)
		}
	}, [ref, handler])
}

const StyledHeader = styled.header`
	position: relative;
	z-index: 10000;
	width: 100%;
	padding: 1rem 0;
	/* background-color: #f0bad8; */
	/* box-shadow: rgba(67, 90, 111, 0.9) 0px 0px 8px, rgba(67, 90, 111, 0.9) 0px 5px 7px -6px; */
`

const StyledHeaderContent = styled.div`
	/* width: min(80vw, 120rem); */
	width: 90vw;
	margin: 0 auto;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const StyledNavList = styled.ul`
	display: flex;
	list-style: none;
	align-items: center;

	& li:not(:last-child) {
		padding-right: 2.4rem;
		letter-spacing: 1.6px;
	}
`

const Header = () => {
	const [open, setOpen] = useState(false)
	const node = useRef()
	useOnClickOutside(node, () => setOpen(false))

	const [windowDimension, setWindowDimension] = useState(null)

	useEffect(() => {
		setWindowDimension(window.innerWidth)
	}, [])

	useEffect(() => {
		function handleResize() {
			setWindowDimension(window.innerWidth)
		}

		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	const isMobile = windowDimension <= 768

	return (
		<StyledHeader ref={node}>
			<StyledHeaderContent>
				<Link to="/">
					<img src={logo} alt="logo" style={{ height: "4rem", marginTop: "0.8rem" }} />
				</Link>
				{isMobile ? (
					<>
						<Burger open={open} setOpen={setOpen} />
						<Menu open={open} setOpen={setOpen} />
					</>
				) : (
					<StyledNavList>
						<li>
							<Link to="/work">Work</Link>
						</li>
						<li>
							<Link to="/education">Education</Link>
						</li>
						<li>
							<Link to="/about">About</Link>
						</li>
						<li>
							<a href="https://becca-fipphen-content.s3.amazonaws.com/rebecca-fipphen-resume.pdf">
								<TempButton>Download Resume</TempButton>
							</a>
						</li>
					</StyledNavList>
				)}
			</StyledHeaderContent>
		</StyledHeader>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string
}

Header.defaultProps = {
	siteTitle: ``
}

export default Header
