import React from "react"
import styled from "styled-components"
import { Text } from "../gazebo"
// import logoWhite from "../images/logo-white.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { GetInTouch } from "./GetInTouch"
const StyledFooter = styled.footer`
	width: 100%;
	padding: 4.8rem 0;
	background-color: #0a1045;
`
const Footer = () => {
	return (
		<>
			<GetInTouch />
			<StyledFooter id="contact">
				<div style={{ textAlign: "center", width: "100%" }}>
					<Text fontSize={200} style={{ margin: "0 auto", color: "#ffffff" }}>
						&copy; {new Date().getFullYear()} Becca Fipphen. All Rights Reserved.
					</Text>
					<Text fontSize={200} style={{ margin: "0 auto", color: "#ffffff" }}>
						Built with <FontAwesomeIcon icon={faHeart} /> in CT &amp; MA.
					</Text>
				</div>
			</StyledFooter>
		</>
	)
}

export { Footer }
