import React from "react"
import styled from "styled-components"

const StyledButton = styled.span`
	/* background: ${(props) => props.theme.colors.primary.default}; */
	background: #b7245c;
	color: #ffffff;
	text-transform: uppercase;
	text-decoration: none;
	/* font-weight: 700; */
	letter-spacing: 0.05em;
	font-size: 1.4rem;
	display: inline-block;
	padding: 1rem 2rem;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	cursor: pointer;
	border-radius: 4px;
	font-family: ${(props) => props.theme.fontFamilies.buttonFontFamily.default};
	line-height: none;
	box-shadow: 4px 4px 0 #27251f;
	transition: all 0.3s ease;

	&:hover {
		box-shadow: 2px 2px 0 #27251f;
	}
`

const TempButton = ({ children }) => {
	return <StyledButton>{children}</StyledButton>
}

export { TempButton }
