import React from "react"
import { StyledMenu } from "./Menu.styled"
import { Link } from "gatsby"
import { TempButton } from "../TempButton"

const Menu = ({ open }) => {
	return (
		<>
			<StyledMenu open={open} fontSize={600}>
				<ul style={{ listStyle: "none", marginLeft: "2.4rem" }}>
					<li>
						<Link to="/work">Work</Link>
					</li>
					<li>
						<Link to="/education">Education</Link>
					</li>
					<li>
						<Link to="/about">About</Link>
					</li>
					<li>
						<a href="https://becca-fipphen-content.s3.amazonaws.com/rebecca-fipphen-resume.pdf">
							<TempButton>Download Resume</TempButton>
						</a>
					</li>
				</ul>
			</StyledMenu>
			<div className="backdrop"></div>
		</>
	)
}

export { Menu }
